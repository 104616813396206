import { render, staticRenderFns } from "./PunishMessage.vue?vue&type=template&id=f82e7cee&scoped=true&"
import script from "./PunishMessage.vue?vue&type=script&lang=ts&"
export * from "./PunishMessage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f82e7cee",
  null
  
)

export default component.exports